<template>
    <div class="content-wrap">
        <div class="main">
            <div class="top">
                <p class="title">精品及改装件详情</p>
                <div class="detail">
                    <div class="left-wrapper">
                        <div class="big-img">
                            <img :src="bigImgUrl" :style="bigImgStyle" @click="bigImgClick" />
                        </div>
                        <div class="small-imgs">
                            <span
                                v-if="data.attachments && data.attachments.length > 2"
                                @click="doPrev"
                                class="icon iconfont icon-left"></span>
                            <span
                                v-if="data.attachments && data.attachments.length > 2"
                                @click="doNext"
                                class="icon iconfont icon-right"></span>
                            <div class="small-imgs-wrap" ref="smallImgsParent">
                                <div class="move-box" ref="smallImgsBox" :style="{ left: currentLeft }">
                                    <div
                                        class="small-img"
                                        v-for="(item, index) in data.attachments"
                                        @click="setBigImgUrl(item.path, index)"
                                        :key="index">
                                        <img :src="item.path" v-default-img="nopic" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="right-wrapper">
                        <div class="part-info-wrap">
                            <div class="part-info">
                                <span class="label">类别分类：</span>
                                <span class="info ellipsis">{{ data.categoryClassifyName }}</span>
                            </div>
                            <div class="part-info">
                                <span class="label">配件分类：</span>
                                <span class="info ellipsis">{{ data.partClassify }}</span>
                            </div>
                            <div class="part-info">
                                <span class="label">车型：</span>
                                <span class="info ellipsis">{{ data.modelPlatformName }}</span>
                            </div>
                            <div class="part-info">
                                <span class="label">配件名称：</span>
                                <span class="info ellipsis">{{ data.partName }}</span>
                            </div>
                            <div class="part-info">
                                <span class="label">配件编号：</span>
                                <span class="info ellipsis">{{ data.partCode }}</span>
                            </div>
                            <div class="part-info">
                                <span class="label">单车用量：</span>
                                <span class="info ellipsis">{{ data.consumptionPerCar }}</span>
                            </div>
                            <div class="part-info">
                                <span class="label">参考价格：</span>
                                <span class="info ellipsis">{{ data.referencePrice }}</span>
                            </div>
                            <div class="part-info">
                                <span class="label">最小起订量：</span>
                                <span class="info ellipsis">{{ data.minOrderQuantity }}</span>
                            </div>
                            <div class="part-info">
                                <span class="label">用法备注：</span>
                                <span class="info ellipsis">{{ data.keyword }}</span>
                            </div>
                        </div>
                        <Button :size="buttonSize" type="primary" @click="addToCart"><i
                                class="iconfont icon-shopping"></i>&nbsp;加入购物车</Button>
                    </div>
                </div>
            </div>
        </div>
        <viewer ref="viewer" :images="viewImageLists">
            <img v-for="item in viewImageLists" :key="item.id" :src="item.path" style="display: none" />
        </viewer>
    </div>
</template>

<script>
import { scalingDownCalc } from '@/common/utils';
import { request } from '@/network/request';
import nopic from '@/assets/images/nopic.png';

export default {
    data() {
        return {
            data: {},
            nopic: nopic,
            currentLeft: '0px',
            maxLeft: '',
            bigImgUrl: nopic,
            bigImgIndex: 0,
            bigImgStyle: '',
            tableHeight: 0,
            viewImageLists: [],
            buttonSize: 'large'
        };
    },
    created() { },
    watch: {
        data: function () {
            // 计算是否需要显示左右箭头按钮
            this.$nextTick(() => {
                this.maxLeft = this.$refs.smallImgsParent.offsetWidth - this.$refs.smallImgsBox.offsetWidth;
            });
        },
        $route: {
            handler($route) {
                if ($route.name == 'dullThingDetail') {
                    this.getPartDetail();
                }
            }
        }
    },

    mounted() {
        this.getPartDetail();
    },

    methods: {
        getPartDetail() {
            const url = `/mh/quality/part/detail?id=${this.$route.query.id}`;
            this.$Spin.show();

            request
                .get(url)
                .then(data => {
                    if (data.success) {
                        let result = data.result || {};
                        this.data = result;
                        if (result.attachments && result.attachments.length > 0) {
                            this.setBigImgUrl(result.attachments[0].path, 0);
                            this.viewImageLists = result.attachments;
                        }
                    } else {
                        this.$Message.error(data.message);
                    }
                })
                .finally(() => {
                    this.$Spin.hide();
                });
        },

        // 备件照片宽高不定，渲染的前重新计算备件照片宽高
        setBigImgUrl(src, index) {
            this.bigImgIndex = index;
            this.loadImage(src, { width: 400, height: 400 });
        },

        // 加载图片
        loadImage: function (url, imgParentSize) {
            let self = this,
                img = new Image();

            img.onload = function () {
                self.showViewerImage(this, url, imgParentSize);
            };

            img.onerror = function () {
                var errImg = new Image(),
                    src = self.nopic;

                errImg.onload = function () {
                    self.showViewerImage(this, src, imgParentSize);
                };

                errImg.src = src;
            };

            img.src = url;
        },

        // 切换显示大图片
        showViewerImage(img, url, imgParentSize) {
            let imgWidth = img.width,
                imgHeihgt = img.height,
                outerWidth = imgParentSize.width,
                outerHeight = imgParentSize.height,
                size = scalingDownCalc(imgWidth, imgHeihgt, outerWidth, outerHeight),
                imageStyle = {
                    width: size.width + 'px',
                    height: size.height + 'px'
                };

            this.bigImgUrl = url;
            this.bigImgStyle = imageStyle;
        },

        // 图片左边箭头点击事件
        doPrev() {
            let left = this.$refs.smallImgsBox.offsetLeft;
            if (left >= this.maxLeft) {
                left += 103;
                if (left >= 0) {
                    this.currentLeft = 0 + 'px';
                } else {
                    this.currentLeft = left + 'px';
                }
            }
        },

        // 图片右边箭头点击事件
        doNext() {
            let left = this.$refs.smallImgsBox.offsetLeft;
            if (left >= this.maxLeft) {
                left -= 103;
                if (left <= this.maxLeft) {
                    this.currentLeft = this.maxLeft + 'px';
                } else {
                    this.currentLeft = left + 'px';
                }
            }
        },
        bigImgClick() {
            this.$refs.viewer.$viewer.show().view(this.bigImgIndex);
        },
        addToCart() {
            const params = {
                qualityId: this.$route.query.id,
                ...this.data
            };

            this.$Spin.show();

            request
                .post('/mh/shopping/cart/add', params)
                .then(data => {
                    if (data.success) {
                        this.$Message.success('添加成功');
                    } else {
                        this.$Message.error(data.message);
                    }
                })
                .finally(() => {
                    this.$Spin.hide();
                });
        }
    }
};
</script>
<style lang="less" scoped>
.content-wrap {
    width: 100%;
    height: 100%;
    background-color: #ffffff;

    .main {
        width: 70%;
        height: 100%;
        margin: 0 auto;

        .top {
            padding: 5px 0px;
            margin-top: 10px;
            border: 1px @border-color solid;

            .title {
                padding: 0 0 5px 10px;
                border-bottom: 1px @border-color solid;

                font-size: 17px;
                color: #00176b;
                font-weight: 600;
            }

            .detail {
                display: flex;
                padding: 15px 15px;
                min-width: 700px;
                max-width: 1000px;

                .left-wrapper {
                    width: 400px;
                    margin-right: 16px;

                    .big-img {
                        width: 400px;
                        height: 400px;
                        line-height: 300px;
                        text-align: center;
                        border: 1px solid @border-color;
                        border-radius: 4px;

                        img {
                            vertical-align: middle;
                        }
                    }

                    .small-imgs {
                        margin-top: 10px;
                        font-size: 0;
                        height: 70px;
                        padding: 0 23px;
                        position: relative;

                        .left-arrow {
                            position: absolute;
                            left: 0;
                            top: 15px;
                            width: 20px;
                            height: 40px;
                            cursor: pointer;
                            color: black;
                            background-color: pink;
                        }

                        .right-arrow {
                            position: absolute;
                            right: 0;
                            top: 15px;
                            width: 20px;
                            height: 40px;
                            cursor: pointer;
                            color: black;
                            background-color: pink;
                        }

                        .small-imgs-wrap {
                            position: relative;
                            height: 100%;
                            overflow: hidden;
                        }

                        .move-box {
                            position: absolute;
                            white-space: nowrap;
                        }

                        .small-img {
                            display: inline-block;
                            width: 100px;
                            height: 70px;
                            text-align: center;
                            vertical-align: middle;
                            line-height: 68px;
                            cursor: pointer;
                            margin-right: 3px;
                            border: 1px solid @border-color;
                            border-radius: 4px;

                            img {
                                width: 98px;
                                height: 68px;
                                vertical-align: middle;
                            }
                        }
                    }
                }

                .right-wrapper {
                    padding-top: 10px;
                    width: calc(100% - 416px);
                    display: flex;
                    flex-direction: column;

                    .part-info-wrap {
                        margin-left: 20px;

                        .part-info {
                            line-height: 20px;
                            margin-bottom: 18px;
                            font-size: 14px;

                            .label {
                                display: inline-block;
                                width: 85px;
                                text-align: left;
                                margin-right: 10px;
                            }

                            .info {
                                color: @back-65;
                                max-width: 130px;
                            }
                        }
                    }

                    .ivu-btn {
                        width: 240px;
                        height: 40px;
                        background: #005ad5;
                        border-radius: 2px;
                        margin-top: 100px;
                    }
                }
            }
        }
    }
}
</style>
